import { Box, Container, Typography } from '@mui/material'

const NotFound404 = () => {
    return (
        <Container maxWidth="xl" sx={{ textAlign: "center", backgroundColor: 'white' }}>
          
          <Box sx={{ textAlign: "center", px: 5 }}>
            <img src='/assets/logo-full.svg' style={{ width: '80%' }} />
          </Box>
          <Typography variant='h1' sx={{fontSize: '50px'}}>
            Deze pagina kan niet gevonden worden.
          </Typography>
          <Box sx={{ textAlign: "center", p:5, pb: 0 }}>
            <img src='/assets/404.png' style={{ width: '60%' }} />
          </Box>
        </Container>
    )
}

export default NotFound404