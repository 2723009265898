import { Link } from '@mui/material';
import { useEffect, useState } from 'react';
import RouterLink from './router-link';
import { useLocation } from 'react-router-dom';

const navItems = [
  {
    name: "Home",
    path: '/home',
    color: "#DEA6A7",
    textColor: "#333"
  },
  {
    name: "Over ons",
    path: '/about-us',
    color: "#E2B99B",
    textColor: "#333"
  },
  {
    name: "Dagverloop",
    path: '/daily-routine',
    color: "#F7E0C1",
    textColor: "#333"
  },
  {
    name: "Foto's",
    path: '/media',
    color: "#CEF7C5",
    textColor: "#333"
  },
  {
    name: "Openingsuren",
    path: '/opening-hours',
    color: "#CEE0E0",
    textColor: "#333"
  },
  {
    name: "Contact",
    path: '/contact',
    color: "#CDB6D2",
    textColor: "#333"
  }
];

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      // Check if the window width is greater than 1199
      if (window.innerWidth > 599) {
        // Close the menu by setting menuOpen to false
        setMenuOpen(false);
      }
    };

    // Attach the event listener to the window resize event
    window.addEventListener('resize', handleResize);

    // Call the handleResize function once to set the initial state
    handleResize();

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount


  return (
    <>
      <div
        className={menuOpen ? "menu open" : "menu"}
        onClick={toggleMenuOpen}
      >
        <div className="icon" />
      </div>
      <nav
        className={menuOpen ? "fill open" : "fill"}
      >
        <div
          id='nav-logo'
          style={{
            visibility: menuOpen ? "visible" : "hidden",
            textAlign: "left",
            width: "100vw",
            backgroundColor: "#fff"
          }}
        >
          <img
            style={{
              marginLeft: "50%",
              transform: "translate(-50%, 0)",
              padding: "15px",
              maxWidth: "200px",
              width: "calc(100vw - 100px)"
            }}
            src="/assets/logo/logo-zalm-rand.png"
            alt="navbar logo de kleine ster"
          />
        </div>
        <ul>
          {navItems.map((navItem, index) => (
            <li
              key={index}
              style={{
                borderBottom: `5px solid ${navItem.color}`
              }}
            >
              <Link
                component={RouterLink}
                className={location.pathname == navItem.path ? "selected" : "not-selected"}
                style={{
                  '--fill-text-color': navItem.textColor,
                  '--fill-color': navItem.color
                }}
                href={navItem.path}
                onClick={toggleMenuOpen}
              >
                {navItem.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div
        id='nav-spacer'
      />
    </>
  );
}

export default (Navbar);