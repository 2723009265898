import { Navigate, Outlet, useRoutes } from "react-router-dom"
import Home from "../pages/home"
import NotFound404 from "../pages/404"
import AboutUs from "../pages/about-us";
import Media from "../pages/media";
import OpeningHours from "../pages/opening-hours";
import Contact from "../pages/contact";
import DailyRoutine from "../pages/daily-routine";

const Router = () => {
  const routes = useRoutes([
    {
      element: (
        <>
          <Outlet/>
        </>
      ),
      children: [
        { element: <Home />, index: true },
        { element: <Home />, path: 'home'},
        { element: <AboutUs />, path: 'about-us'},
        { element: <DailyRoutine />, path: 'daily-routine'},
        { element: <Media />, path: 'media'},
        { element: <OpeningHours />, path: 'opening-hours'},
        { element: <Contact />, path: 'contact'}
      ]
    },
    {
      path: '404',
      element: <NotFound404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes
}

export default Router