import { Box, Container, Divider, Typography } from '@mui/material'

const OpeningHours = () => {
  return (
    <Container maxWidth="xl" sx={{ textAlign: "center", backgroundColor: '#ffffffaa' }}>
      <Box sx={{ pt: 5, m: "auto" }}>
        <Box id="openinghours-tables">
          <div id='openinghours'>
            <table id='openinghours-table'>
              <tbody>
                <tr>
                  <th className='title' colSpan="2">
                    <Typography variant='h1' sx={{ fontSize: '30px', marginBottom: 2 }}>Openingsuren</Typography>
                  </th>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>maandag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>dinsdag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>woensdag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>donderdag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>vrijdag</th>
                  <td>
                    7u00 - 18u00
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>zaterdag</th>
                  <td className='closed'>
                    Gesloten
                  </td>
                </tr>
                <tr>
                  <th style={{ paddingRight: 10 }}>zondag &<br />feestdagen</th>
                  <td className='closed'>
                    Gesloten
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id='closingDays'>
            <table id='closingdays-table'>
              <tr>
                <th className='title' colSpan="2">
                  <Typography variant='h1' sx={{ fontSize: '30px', marginBottom: 2 }}>Sluitingsdagen 2024</Typography>
                </th>
              </tr>
              <tr>
                <th style={{ paddingRight: 10 }}>
                  Paasvakantie
                </th>
                <td>
                  01/04 - 05/04
                </td>
              </tr>
              <tr>
                <th style={{ paddingRight: 10 }}>
                  Zomervakantie
                </th>
                <td>
                  15/07 - 26/07<br />
                  12/08 - 16/08
                </td>
              </tr>
              <tr>
                <th style={{ paddingRight: 10 }}>
                  Kerstvakantie
                </th>
                <td>
                  23/12 - 03/01
                </td>
              </tr>
              <br />
              <tr>
                <th style={{ paddingRight: 10 }}>
                  Dag Van De Arbeid
                </th>
                <td>
                  01/05
                </td>
              </tr>
              <tr>
                <th style={{ paddingRight: 10 }}>
                  O.L. Heer Hemelvaart
                </th>
                <td>
                  09/05
                </td>
              </tr>
              <tr>
                <th style={{ paddingRight: 10 }}>
                  Brugdag
                </th>
                <td>
                  10/05
                </td>
              </tr>
              <tr>
                <th style={{ paddingRight: 10 }}>
                  Pinkstermaandag
                </th>
                <td>
                  20/05
                </td>
              </tr>
              <tr>
                <th style={{ paddingRight: 10 }}>
                  Allerheiligen
                </th>
                <td>
                  1/11
                </td>
              </tr>
              <tr>
                <th style={{ paddingRight: 10 }}>
                  Wapenstilstand
                </th>
                <td>
                  11/11
                </td>
              </tr>
            </table>
          </div>
        </Box>
      </Box>
    </Container>
  )
}

export default OpeningHours