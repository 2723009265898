import { Box, Card, CardContent, CardMedia, Container, Divider, Typography } from '@mui/material'

const AboutUs = () => {
    return (
        <Container maxWidth="xl" sx={{textAlign: "center", backgroundColor: '#ffffffaa'}}>
          <Box sx={{pt:5, m: "auto"}}>
            <Typography variant="h1" sx={{fontSize: 50}}>
              Over ons
            </Typography>
          </Box>
          <Box sx={{pt:2, pb: 5, m: "auto", width: "80%"}}>
            <Typography variant="p" sx={{fontSize: 20}}>
              Wij zijn een zelfstandige kinderopvang met een maximum capaciteit van 18 kindjes en staan onder toezicht van Kind en Gezin.<br/><br/>
              Kinderen en ouders staan bij ons op de eerste plaats!<br/><br/>
              Wij willen kwaliteitsvolle kinderopvang aanbieden voor ieder kind.
              Dit doen we door onder andere een veilige en kindvriendelijke omgeving te creëren waarin kinderen hun eigen talenten kunnen ontwikkelen en hun vaardigheden kunnen ontplooien.<br/><br/>
              In onze opvang werken we met een verticale groep wat wil zeggen dat peuters en baby’s samen in één open ruimte spelen.
              Hierdoor leren de peuters omgaan met kleine baby’s en anderzijds leren de baby’s van de peuters.
              We hebben wel steeds een aparte babyhoek waar baby’s kunnen vertoeven wanneer het te druk wordt bij de peuters.<br/><br/>
              En we willen vooral luisteren naar jou als ouder, want we vervangen jou niet, we vullen je aan op het moment dat jij elders moet zijn.
              Jij blijft voor het kind de belangrijkste persoon en je kent je kindje het beste.
              We willen hier respectvol mee omgaan.
              Alles begint bij vertrouwen.
            </Typography>
          </Box>
          <Divider/>
          <Box sx={{pt:5, m: "auto"}}>
            <Typography variant="h1" sx={{fontSize: 50}}>
              Het team
            </Typography>
          </Box>
          <Box sx={{pt:2, pb: 5, m: "auto", width: "80%"}}>
            <Card sx={{ borderRadius: 15, border: '2px solid #4F99A5', display: 'flex', flexFlow: 'row wrap', mb: 2, boxShadow: '10px 10px 8px #00000010'}}>
              <CardContent sx={{textAlign: 'left', flex: '50%'}}>
                <Box sx={{p: 3, pb:0}}>
                  <Typography variant='h2' sx={{fontSize: 20, fontWeight: 600}}>
                    Yana Van Lander
                  </Typography>
                </Box>
                <Box sx={{p:5, pt: 1}}>
                  <Typography variant='p' sx={{fontSize: 16}}>
                  Hallo! Ik ben de verantwoordelijke en organisator van kinderdagverblijf De Kleine Ster en ben geboren in 1998.<br></br>
                  Ik ben in het bezit van de attesten ‘begeleidster in de kinderopvang’ en ‘organisatorisch beheer in de kinderopvang’.<br></br>
                  Ik heb altijd de grote droom gehad om een eigen kinderdagverblijf te hebben en op 1 januari 2024 werd deze droom werkelijkheid en nam ik kinderdagverblijf De Kleine Ster over.<br></br>
                  Creatief bezig zijn met de kindjes is het liefste wat ik doe, al kan ik ook zeker genieten van al die liefde en knuffels die je van de kindjes krijgt.

                  </Typography>
                </Box>
              </CardContent>
              <CardMedia
                sx={{ height: 300, width: 300, borderRadius: 15 }}
                image="/assets/team/Yana.png"
                title="Yana van lander"
              />
            </Card>

            <Card sx={{ borderRadius: 15, border: '2px solid #4F99A5', display: 'flex', flexFlow: 'row wrap', mb: 2, boxShadow: '10px 10px 8px #00000010'}}>
              <CardMedia
                sx={{ height: 300, width: 300, borderRadius: 15 }}
                image="/assets/team/Ans.png"
                title="Ans Embrechts"
              />
              <CardContent sx={{textAlign: 'left', flex: '50%'}}>
                <Box sx={{p: 3, pb:0}}>
                  <Typography variant='h2' sx={{fontSize: 20, fontWeight: 600}}>
                    Ans Embrechts
                  </Typography>
                </Box>
                <Box sx={{p:5, pt: 1}}>
                  <Typography variant='p' sx={{fontSize: 16}}>
                  Ans Embrechts is medeorganisator en heeft een diploma van Gezondheid en welzijnswetenschappen.<br></br>
                  Ze is geboren in 1984 en heeft twee leuke kapoenen, Rube (2010) en Lise (2013).<br></br>
                  Iedere dag zorgt zij ervoor dat de kinderen zich goed voelen in de opvang en dat ze een leuke dag beleven.<br></br>
                  Verhaaltjes lezen, buiten spelen, de baby’s entertainen, met de bus wandelen,… Ze kan het allemaal!
                  </Typography>
                </Box>
              </CardContent>
            </Card>

            <Card sx={{ borderRadius: 15, border: '2px solid #4F99A5', display: 'flex', flexFlow: 'row wrap', mb: 2, boxShadow: '10px 10px 8px #00000010'}}>
              <CardContent sx={{textAlign: 'left', flex: '50%'}}>
                <Box sx={{p: 3, pb:0}}>
                  <Typography variant='h2' sx={{fontSize: 20, fontWeight: 600}}>
                    Rachel Van Dyck
                  </Typography>
                </Box>
                <Box sx={{p:5, pt: 1}}>
                  <Typography variant='p' sx={{fontSize: 16}}>
                  Rachel Van Dyck is medeorganisator en is afgestudeerd als kinderverzorgster.<br></br>
                  Ze werkt sinds 2011 in De Kleine Ster. Zelf heeft ze ook 2 kindjes ( Twan en Stans ).<br></br>
                  Rachel komt iedere dag vrolijk werken en houdt ervan om creatief bezig te zijn met de kindjes.<br></br>
                  Zij gaat geen enkele uitdaging uit de weg. De kindjes zien haar dan ook supergraag!
                  </Typography>
                </Box>
              </CardContent>
              <CardMedia
                sx={{ height: 300, width: 300, borderRadius: 15 }}
                image="/assets/team/Rachel.png"
                title="Yana van lander"
              />
            </Card>
          </Box>
        </Container>
    )
}

export default AboutUs