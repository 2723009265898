import './App.css';
import Navbar from './components/navbar';
import Router from './components/router';

function App() {
  return (
    <div id='app'>
      <Navbar/>
      <Router/>
      {/* <div id='copyright'>
        © Baeyens Robbe 2024
      </div> */}
    </div>
  );
}

export default App;
