import { Box, Container, Divider, Typography } from '@mui/material'
import { useRef } from 'react';

const Contact = () => {
  const windowSize = useRef([
    window.innerWidth,
    window.innerHeight,
  ]);

  return (
    <Container maxWidth="xl" sx={{ textAlign: "left", backgroundColor: '#ffffffaa' }}>
      <Box sx={{ pt: 5, m: "auto" }}>
        <Typography variant="h1" sx={{ fontSize: 50, textAlign: "center" }}>
          Contact
        </Typography>
      
      <Box sx={{pt:2, pb: 5, m: "auto", width: "80%", textAlign: 'center'}}>
            <Typography variant="p" sx={{fontSize: 20}}>
            Aanvragen dienen steeds te gebeuren via <a style={{color: 'black'}} href="https://opvang.vlaanderen/opvanglocatie/a18b7dfc1ba2430ba92191e46247fb38"> De Kleine ster | Opvang.Vlaanderen</a><br/>
Heb je nog vragen aarzel dan niet om ons te contacteren.


            </Typography>
          </Box>
          <Divider/>
        <table id='contact-table'>
          <tbody>
            <tr>
              <th>
                <img src='/assets/contact/map.jpg' width={50} alt='Adres' title='Adres' style={{borderRadius: 50}}/><br></br>
              </th>
              <td>
                Gierlebaan 106<br />
                2460 Tielen
              </td>
            </tr>
            <tr>
              <th>
                <img src='/assets/contact/phone.jpg' width={50} alt='Telefoon' title='Telefoon' style={{borderRadius: 50}}/><br></br>
              </th>
              <td><a href="tel:014718584">014 / 71 85 84</a></td>
            </tr>
            <tr>
              <th>
              <img src='/assets/contact/mail.jpg' width={50} alt='Mail' title='Mail' style={{borderRadius: 50}}/><br></br>
              </th>
              <td><a href="mailto:kdvdekleinester@hotmail.com">kdvdekleinester@hotmail.com</a></td>
            </tr>
            {/* <tr>
              <th>Aanvragen</th>
              <td>Aanvragen dienen steeds te gebeuren via :<br /><a href="https://opvang.vlaanderen/opvanglocatie/a18b7dfc1ba2430ba92191e46247fb38"> De Kleine ster | Opvang.Vlaanderen</a></td>
            </tr> */}
            <tr>
              <th>
              <img src='/assets/contact/facebook.jpg' width={50} alt='Facebook' title='Facebook' style={{borderRadius: 50}}/><br></br>
              </th>
              <td><a href="https://www.facebook.com/people/Kinderdagverblijf-De-Kleine-Ster/61555185384050/"><span>Facebook</span></a></td>
            </tr>
            <tr>
              <th>
              <img src='/assets/contact/insta.jpg' width={50} alt='Instagram' title='Instagram' style={{borderRadius: 50}}/><br></br>
              </th>
              <td><a href="https://www.instagram.com/kinderdagverblijf_dekleinester?igsh=eTNoMWVia29yZXRm"><span>Instagram</span></a></td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Box>
        <iframe id='google-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2497.2861080050197!2d4.885774979069652!3d51.25064131500432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6b2a9591e2e8f%3A0xba5eab1d6554449e!2sGierlebaan%20106%2C%202460%20Kasterlee!5e0!3m2!1snl!2sbe!4v1712517970664!5m2!1snl!2sbe" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </Box>
    </Container>
  )
}

export default Contact