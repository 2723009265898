export const images = [
  {
    src: "/assets/logo/logo-zalm-rand.png",
    original: "/assets/logo/logo-zalm-rand.png",
    width: 1200,
    height: 1200,
    // tags: [
    //   { value: "Buiten", title: "Buiten" },
    // ],
    caption: "Logo",
  },
  {
    src: "/assets/media/buitenkant.png",
    original: "/assets/media/buitenkant.png",
    width: 2000,
    height: 1500,
    // tags: [
    //   { value: "Buiten", title: "Buiten" },
    // ],
    caption: "Voorkant",
  },
  {
    src: "/assets/media/spandoek.png",
    original: "/assets/media/spandoek.png",
    width: 1500,
    height: 2000,
    caption: "Spandoek",
  },
  {
    src: "/assets/media/deurbel.png",
    original: "/assets/media/deurbel.png",
    width: 1500,
    height: 2000,
    caption: "Deurbel",
  },
  {
    src: "/assets/media/buiten_spelen.png",
    original: "/assets/media/buiten_spelen.png",
    width: 1500,
    height: 2000,
    caption: "Buiten spelen",
  },
  {
    src: "/assets/media/buiten_spelen2.png",
    original: "/assets/media/buiten_spelen2.png",
    width: 2000,
    height: 1500,
    caption: "Speeltoestel",
  },
  {
    src: "/assets/media/buiten_koken.png",
    original: "/assets/media/buiten_koken.png",
    width: 2000,
    height: 1500,
    caption: "Buiten keukentje",
  },
  {
    src: "/assets/media/zandbak.png",
    original: "/assets/media/zandbak.png",
    width: 1500,
    height: 2000,
    caption: "Zandbak",
  },
  {
    src: "/assets/media/inkom.png",
    original: "/assets/media/inkom.png",
    width: 1500,
    height: 2000,
    caption: "Inkom",
  },
  {
    src: "/assets/media/kapstokken2.png",
    original: "/assets/media/kapstokken2.png",
    width: 1500,
    height: 2000,
    caption: "Kapstokken inkom",
  },
  {
    src: "/assets/media/sterren.png",
    original: "/assets/media/sterren.png",
    width: 1500,
    height: 2000,
    caption: "Sterren inkom",
  },
  {
    src: "/assets/media/speelruimte2.png",
    original: "/assets/media/speelruimte2.png",
    width: 2000,
    height: 1500,
    caption: "Speelruimte",
  },
  {
    src: "/assets/media/speelruimte1.png",
    original: "/assets/media/speelruimte1.png",
    width: 1500,
    height: 2000,
    caption: "Speelruimte",
  },
  {
    src: "/assets/media/keuken.png",
    original: "/assets/media/keuken.png",
    width: 1500,
    height: 2000,
    caption: "Keuken",
  },
  {
    src: "/assets/media/slaapruimte.png",
    original: "/assets/media/slaapruimte.png",
    width: 1500,
    height: 2000,
    caption: "Babyhoek",
  },
  {
    src: "/assets/media/potje.png",
    original: "/assets/media/potje.png",
    width: 1500,
    height: 2000,
    caption: "Potje",
  },
  {
    src: "/assets/media/kapstokken.png",
    original: "/assets/media/kapstokken.png",
    width: 1500,
    height: 2000,
    caption: "Kapstokken slaapzakjes",
  },
  {
    src: "/assets/media/slaaprijmpje.png",
    original: "/assets/media/slaaprijmpje.png",
    width: 1500,
    height: 2000,
    caption: "Slaaprijmpje",
  },
  {
    src: "/assets/media/slaapruimte2.png",
    original: "/assets/media/slaapruimte2.png",
    width: 1500,
    height: 2000,
    caption: "Mini kamer",
  },
  {
    src: "/assets/media/slaapruimte3.png",
    original: "/assets/media/slaapruimte3.png",
    width: 1500,
    height: 2000,
    caption: "Baby kamer",
  },
  {
    src: "/assets/media/slaapruimte4.png",
    original: "/assets/media/slaapruimte4.png",
    width: 1500,
    height: 2000,
    caption: "Baby kamer",
  },
  {
    src: "/assets/media/slaapruimte5.png",
    original: "/assets/media/slaapruimte5.png",
    width: 1500,
    height: 2000,
    caption: "Grote kamer",
  },
];

export const homeImages = [
  {
    src: "/assets/media/buitenkant.png",
    original: "/assets/media/buitenkant.png",
    width: 2000,
    height: 1500,
    caption: "Voorkant",
  },
  {
    src: "/assets/media/deurbel.png",
    original: "/assets/media/deurbel.png",
    width: 1500,
    height: 2000,
    caption: "Zijkant",
  }
];
