import { Box, Container, Divider, Typography } from '@mui/material'
import { Gallery } from 'react-grid-gallery'
import { homeImages } from '../media/images'
import { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Home = () => {
  const [index, setIndex] = useState(-1);

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);

  return (
    <Container maxWidth="xl" sx={{ textAlign: "center", backgroundColor: '#ffffffaa', position: 'sticky', top: '65px' }}>
      <Box sx={{ pt: 5, m: "auto" }}>
        <Typography variant="h1" sx={{ fontSize: 50 }}>
          Home
        </Typography>
      </Box>
      <Box sx={{display: "flex", flexWrap: 'wrap', justifyContent: 'center'}}>
        <img src='/assets/logo/logo-zalm-rand.png' width={165} height={165}/>

        <Box sx={{ textAlign: "center", p: 5 }}>
          <Typography variant="p" sx={{ fontSize: 24 }}>
            Welkom op de website van Kinderdagverblijf <strong>De Kleine Ster</strong>!<br />
            Ben je op zoek naar een warme opvang voor je kleine spruit?<br />
            Dan ben je hier zeker aan het juiste adres!
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Box id="home-img-box">
        <Gallery
          images={homeImages}
          onClick={handleClick}
          enableImageSelection={false}
          rowHeight={600}
        />
        <Lightbox
          slides={homeImages}
          open={index >= 0}
          index={index}
          close={handleClose}
          // enable optional lightbox plugins
          plugins={[Fullscreen, Thumbnails, Zoom]}
        />
      </Box>
    </Container>
  )
}

export default Home