import { Box, Container, Divider, Typography } from '@mui/material'

const DailyRoutine = () => {
  return (
    <Container maxWidth="xl" sx={{ backgroundColor: '#ffffffaa', textAlign: 'center' }}>

      {/* <Box sx={{ textAlign: "center", px: 5 }}>
        <img src='/assets/logo-full.svg' style={{ width: '80%' }} />
      </Box>
      <Typography variant='h1' sx={{fontSize: '50px'}}>
        Er word gebouwd aan deze website.
      </Typography>
      <Box sx={{ textAlign: "center", px: 5, p:5 }}>
        <img src='/assets/bouwen.png' style={{ width: '30%' }} />
      </Box> */}
      <Box sx={{ pt: 5, m: "auto" }}>
        <Typography variant="h1" sx={{ fontSize: 50 }}>
          Dagverloop
        </Typography>
      </Box>
      
      <Box sx={{pt:2, pb: 5, m: "auto", width: "80%"}}>
            <Typography variant="p" sx={{fontSize: 20}}>
            Het dagverloop van de baby’s wordt aangepast aan de behoefte en het ritme van het kindje. <br></br><br></br>
Vanaf de kruipers trachten we dagelijks aan het dagritme te houden. Dit brengt duidelijkheid en structuur voor de kindjes. <br></br>Ze voelen zich veilig omdat ze weten wat er de rest van de dag komt.

            </Typography>
          </Box>
          <Divider/>
          <br />
      <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"></link>
      <div className="container bootstrap snippets bootdeys">
        <div className="col-md-9">
          <div className="timeline-centered timeline-sm">
            <article className="timeline-entry">
              <div className="timeline-entry-inner">
                {/* <time datetime="2024-01-01T07:00" className="timeline-time"><span>07:00</span><span></span></time> */}
                <div className="timeline-icon bg-blue">
                  {/* <i className="fa fa-exclamation"></i> */}
                  <time datetime="2024-01-01T07:00"><strong>07:00</strong></time>
                </div>
                <div className="timeline-label bg-blue">
                  <h2 className="timeline-title">Onthaal</h2>
                  {/* <img src='/assets/daily-routine/onthaal.png' width={120} style={{ borderRadius: 10 }} /> */}
                  <p>Nadat u uw kindje heeft afgezet, kan het samen met andere vriendjes spelen in de speelruimte. Iedere ochtend wordt de ruimte speelklaar gezet waardoor u kindje meteen op ontdekking kan gaan.</p>
                </div>
              </div>
            </article>

            <article className="timeline-entry left-aligned">
              <div className="timeline-entry-inner">
                {/* <time datetime="2024-01-01T09:00" className="timeline-time"><span>9:00</span><span></span></time> */}
                <div className="timeline-icon bg-yellow">
                  {/* <i className="fa fa-group"></i> */}
                  <time datetime="2024-01-01T09:00"><strong>09:00</strong></time>
                </div>
                <div className="timeline-label bg-yellow">
                  <h2 className="timeline-title">Tussendoortje</h2>
                  {/* <img src='/assets/daily-routine/fruitje.png' width={120} style={{ borderRadius: 10 }} /> */}
                  <p>De kruipers gaan een dutje doen boven in het bedje. De grote kindjes eten een stukje fruit/groente. Bij goed weer eten wij ons tussendoortje buiten.</p>
                </div>
              </div>
            </article>

            <article className="timeline-entry">
              <div className="timeline-entry-inner">
                {/* <time datetime="2024-01-01T07:00" className="timeline-time"><span>07:00</span><span></span></time> */}
                <div className="timeline-icon bg-blue">
                  {/* <i className="fa fa-exclamation"></i> */}
                  <time datetime="2024-01-01T09:15"><strong>09:15</strong></time>
                </div>
                <div className="timeline-label bg-blue">
                  <h2 className="timeline-title">Begeleide activiteit</h2>
                  {/* <img src='/assets/daily-routine/activiteit1.png' width={120} style={{ borderRadius: 10 }} />
                  <img src='/assets/daily-routine/activiteit2.png' width={120} style={{ borderRadius: 10, marginLeft: 20 }} /> */}
                  <p>We controleren de luiers en er wordt tijd gemaakt voor op het potje te gaan. Vervolgens wordt er een geleide (knutsel) activiteit aangeboden. Kindjes die willen kunnen deelnemen aan deze activiteit. Bij goed weer wordt er buiten gespeeld of gaan we wandelen met onze bus.</p>
                </div>
              </div>
            </article>

            <article className="timeline-entry left-aligned">
              <div className="timeline-entry-inner">
                {/* <time datetime="2024-01-01T09:00" className="timeline-time"><span>9:00</span><span></span></time> */}
                <div className="timeline-icon bg-yellow">
                  {/* <i className="fa fa-group"></i> */}
                  <time datetime="2024-01-01T11:00"><strong>11:00</strong></time>
                </div>
                <div className="timeline-label bg-yellow">
                  <h2 className="timeline-title">Handjes wassen en middagmaal</h2>
                  {/* <img src='/assets/daily-routine/wassen.png' width={120} style={{ borderRadius: 10 }} />
                  <img src='/assets/daily-routine/eten.png' width={120} style={{ borderRadius: 10, marginLeft: 20 }} /> */}
                  <p>De kindjes gaan hun handjes wassen aan de voor hen op maat gemaakte wastafel. Daarna doen we een slabbetje aan en serveren we een vers gemaakte maaltijd. De kindjes drinken nadien water en wassen zichzelf met een washandje.</p>
                </div>
              </div>
            </article>

            <article className="timeline-entry">
              <div className="timeline-entry-inner">
                {/* <time datetime="2024-01-01T07:00" className="timeline-time"><span>07:00</span><span></span></time> */}
                <div className="timeline-icon bg-blue">
                  {/* <i className="fa fa-exclamation"></i> */}
                  <time datetime="2024-01-01T11:30"><strong>11:30</strong></time>
                </div>
                <div className="timeline-label bg-blue">
                  <h2 className="timeline-title">Verschonen en bedtijd</h2>
                  {/* <img src='/assets/daily-routine/verschonen.png' width={120} style={{ borderRadius: 10 }} /> */}
                  <p>Luiers worden nogmaals verschoond en er wordt tijd gemaakt voor potjes training. Onder begeleiding gaan we met z'n allen naar boven. Er wordt een slaapzakje aangedaan en de tutjes worden uitgedeeld. De kindjes gaan slapen en worden op hun eigen tempo wakker.</p>
                </div>
              </div>
            </article>

            <article className="timeline-entry left-aligned">
              <div className="timeline-entry-inner">
                {/* <time datetime="2024-01-01T09:00" className="timeline-time"><span>9:00</span><span></span></time> */}
                <div className="timeline-icon bg-yellow">
                  {/* <i className="fa fa-group"></i> */}
                  <time datetime="2024-01-01T14:45"><strong>14:45</strong></time>
                </div>
                <div className="timeline-label bg-yellow">
                  <h2 className="timeline-title">Vieruurtje</h2>
                  {/* <img src='/assets/daily-routine/bedtijd.png' width={120} style={{ borderRadius: 10 }} /> */}
                  <p>Alle kindjes genieten van een boterham of fruitpap. Na het vieruutje kunnen de kindjes kiezen uit melk of water om te drinken. Bij kouder weer wordt er verse soep gemaakt voor bij de boterhammen.</p>
                </div>
              </div>
            </article>

            <article className="timeline-entry">
              <div className="timeline-entry-inner">
                {/* <time datetime="2024-01-01T07:00" className="timeline-time"><span>07:00</span><span></span></time> */}
                <div className="timeline-icon bg-blue">
                  {/* <i className="fa fa-exclamation"></i> */}
                  <time datetime="2024-01-01T15:15"><strong>15:15</strong></time>
                </div>
                <div className="timeline-label bg-blue">
                  <h2 className="timeline-title">Vrij spel</h2>
                  {/* <img src='/assets/daily-routine/vieruurtje.png' width={120} style={{ borderRadius: 10 }} /> */}
                  <p>Er wordt vrij spel aangeboden zoals boekjes lezen, liedjes zingen, puzzel maken en nog veel meer.</p>
                </div>
              </div>
            </article>

            <article className="timeline-entry left-aligned">
              <div className="timeline-entry-inner">
                {/* <time datetime="2024-01-01T09:00" className="timeline-time"><span>9:00</span><span></span></time> */}
                <div className="timeline-icon bg-yellow">
                  {/* <i className="fa fa-group"></i> */}
                  <time datetime="2024-01-01T17:00"><strong>17:00</strong></time>
                </div>
                <div className="timeline-label bg-yellow">
                  <h2 className="timeline-title">Tussendoortje</h2>
                  {/* <img src='/assets/daily-routine/vrij.png' width={120} style={{ borderRadius: 10 }} /> */}
                  <p>Voor kindjes die nog aanwezig zijn in de opvang wordt er een tussendoortje voorzien.</p>
                </div>
              </div>
            </article>

            <article className="timeline-entry">
              <div className="timeline-entry-inner">
                {/* <time datetime="2024-01-01T09:00" className="timeline-time"><span>9:00</span><span></span></time> */}
                <div className="timeline-icon bg-blue">
                  {/* <i className="fa fa-group"></i> */}
                  <time datetime="2024-01-01T17:00"><strong>18:00</strong></time>
                </div>
                <div className="timeline-label bg-blue">
                  <h2 className="timeline-title">Sluitingstijd</h2>
                  {/* <img src='/assets/daily-routine/vrij.png' width={120} style={{ borderRadius: 10 }} /> */}
                  <p>De Kleine Ster sluit haar deuren.</p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default DailyRoutine